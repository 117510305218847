import React from 'react';
import { Book } from '../graphqlTypes';

interface PageContentProps {
  children?: React.ReactElement;
  className?: string;
  id?: string;
  noPage?: boolean;
  mainLayout?: React.ReactNode;
  category?: string;
  listProducts?: Book[];
  withoutProps?: boolean;
}

const PageContent = ({
  children,
  className,
  id,
  noPage,
  mainLayout,
  category,
  listProducts,
  withoutProps,
}: PageContentProps): JSX.Element => {
  return (
    <section id={id} className={`${noPage ? 'noPage' : 'pageContent'} ${className || ''}`}>
      {mainLayout && !withoutProps
        ? React.Children.map(children, (child: React.ReactElement) => {
            if (child) {
              return React.cloneElement(child, {
                mainLayout,
                noPage,
                id,
                category,
                listProducts,
              });
            }
            return null;
          })
        : children}
    </section>
  );
};

export default PageContent;
